import Section from 'scripts/core/section';
import { inFrames } from 'scripts/utils/inFrames';
import { Easings } from 'scripts/utils/tweenHelpers';
import { HomePageSections } from 'common/views/sectionTypes';
import gsap from 'gsap';
import { SplitText } from 'scripts/lib/gsap/splitText';
import { ScrollTrigger, createScrollTrigger } from 'scripts/lib/gsap/scrollTrigger';
import { HeaderColorClass, setupTriggerChangeColorHeader, ChangeColorHeaderConfig } from 'scripts/modules/changeColor';
import { BreakpointType, Breakpoints } from 'common/views/appBreakpoints';
import { setupSection } from 'scripts/utils/setupComponents';

const animOffsetY: Record<BreakpointType, number> = {
    [BreakpointType.Desktop]: 160,
    [BreakpointType.Tablet]: 100,
    [BreakpointType.Mobile]: 100,
};


export default class TestimonialsSection extends Section {
    private isActivated: boolean;

    private triggerInView: ScrollTrigger;
    private triggerScaleTitle: ScrollTrigger;
    private triggerExit: ScrollTrigger;
    private splittedAllSplitText: SplitText;

    private get _allSplitText() { return this.element.querySelectorAll('.split'); }

    private get _wrap() { return this.element.querySelector('.testimonials-wrap'); }
    private get _main() { return this.element.querySelector('.testimonials-main'); }
    private get _subtitle() { return this.element.querySelector('.testimonials-subtitle'); }
    private get _title() { return this.element.querySelector('.testimonials-title'); }


    async setupScrollTriggers() {
        const _currentBreakpointType = Breakpoints.Current.breakpoint.name;
        const _currentOffsetAnimY = animOffsetY[_currentBreakpointType];

        const timelineInView = gsap.timeline()
            .fromTo(this._subtitle, {
                y: _currentOffsetAnimY,
            }, {
                y: 0,
                duration: inFrames(80),
                ease: Easings.customEaseInOut,
            })
            .fromTo(this._title.querySelectorAll('.fade-overflow'), {
                y: _currentOffsetAnimY,
            }, {
                y: 0,
                duration: inFrames(80),
                ease: Easings.customEaseInOut,
                stagger: inFrames(2),
            }, inFrames(5))
            .fromTo('.content-wrap', {
                y: _currentOffsetAnimY,
            }, {
                y: 0,
                duration: inFrames(80),
                ease: Easings.customEaseInOut,
                stagger: inFrames(2),
            }, `<${inFrames(2)}`);

        this.triggerInView = createScrollTrigger({
            trigger: this._wrap,
            start: 'top bottom',
            end: 'top top',
            scrub: 1,
            animation: timelineInView,
        });

        if (Breakpoints.isDesktop) {

            const timelineScaleTitle = gsap.timeline()
                .to(this._title, { scale: 0.55, duration: inFrames(80), ease: Easings.customEaseInOut, transformOrigin: 'center left' });

            this.triggerScaleTitle = createScrollTrigger({
                trigger: this._wrap,
                start: 'top top',
                endTrigger: this._main,
                end: 'center center',
                scrub: 1,
                animation: timelineScaleTitle,
            });
        }
    }

    setupSection() {
        const colorConfigWhite: ChangeColorHeaderConfig = {
            element: this.element,
            color: HeaderColorClass.White,
            triggerVars: {
                start: 'top top',
                end: 'bottom top',
            },
        };

        setupTriggerChangeColorHeader(colorConfigWhite);

        this.splittedAllSplitText = new SplitText(this._allSplitText, { type: 'lines', linesClass: 'fade-overflow' });

        this.setupScrollTriggers();
    }

}

setupSection(TestimonialsSection, { id: HomePageSections.Testimonials });
