import gsap from 'gsap';
import { SplitText } from 'scripts/lib/gsap/splitText';
import Section from 'scripts/core/section';
import { inFrames } from 'scripts/utils/inFrames';
import { HomePageSections } from 'common/views/sectionTypes';
import { HeaderColorClass, ChangeColorHeaderConfig, setupTriggerChangeColorHeader } from 'scripts/modules/changeColor';
import { setupSection } from 'scripts/utils/setupComponents';

export default class CampaignsSection extends Section {
    private splittedTitle: SplitText;
    private _isActivated: boolean = false;

    private get _title() { return this.element.querySelector('.animated-title'); }
    private get _subTitle() { return this.element.querySelector('.animated-subtitle'); }
    private get _chartWrapper() { return this.element.querySelector('.animated-chart-wrapper'); }

    async setupSection() {

        const colorConfig: ChangeColorHeaderConfig = {
            element: this.element,
            color: HeaderColorClass.Black,
            triggerVars: {
                start: 'top top',
                end: 'bottom top',
            },
        };

        setupTriggerChangeColorHeader(colorConfig);

        this.splittedTitle = new SplitText(this._title);
    }

    protected _activate() {
        if (this._isActivated) {
            return;
        }

        const timeline = gsap.timeline({ onStart: () => this._isActivated = true });

        const defaultFrom = { y: 160 };
        const defaultTo = {
            y: 0,
            stagger: inFrames(2),
            duration: inFrames(80),
        };

        timeline
            .fromTo(this._subTitle, defaultFrom, defaultTo, 0)
            .fromTo(this.splittedTitle.lines, defaultFrom, defaultTo, inFrames(2))
            .fromTo(this._chartWrapper, defaultFrom, defaultTo, inFrames(this.splittedTitle.lines.length * 2));
    }

    protected _deactivate() {
        /* TODO */
    }
}

setupSection(CampaignsSection, { id: HomePageSections.Campaigns });
