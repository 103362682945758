import gsap from 'gsap';
import { createScrollTrigger } from 'scripts/lib/gsap/scrollTrigger';
import { ScrollSmoother } from 'scripts/lib/gsap/scrollSmoother';
import { SplitText, defaultSplitTextConfig } from 'scripts/lib/gsap/splitText';
import Section from 'scripts/core/section';
import { inFrames } from 'scripts/utils/inFrames';
import { Easings } from 'scripts/utils/tweenHelpers';
import { HomePageSections } from 'common/views/sectionTypes';
import { HeroThreeAnimationBridge, createProgressTimeline } from './animation/bridge';
import { PRELOADER_ACTIVE_CLASS } from 'scripts/utils/constants';
import { ChangeColorHeaderConfig, HeaderColorClass, addHeaderMenuColor, setupTriggerChangeColorHeader } from 'scripts/modules/changeColor';
import { timeoutPromise } from '@zajno/common/async/timeout';
import { HomeHero } from 'scripts/animations/three/home-hero';
import { scrollToTop } from 'scripts/utils/scrollToTop';
import { setupSection } from 'scripts/utils/setupComponents';
import { BreakpointType, Breakpoints } from 'common/views/appBreakpoints';

const DelayForSetupScrollTriggers = 500;

const headerColorChangeEnd: Record<BreakpointType, string> = {
    [BreakpointType.Desktop]: 'bottom-=25% top',
    [BreakpointType.Tablet]: 'bottom-=40% top',
    [BreakpointType.Mobile]: 'bottom-=40% top',
};

export default class HeroSection extends Section {

    private get _smoother() { return ScrollSmoother.get(); }

    private splittedAllSplitText: SplitText;

    private get _allSplitText() { return this.element.querySelectorAll('.split'); }

    private get _content() { return this.element.querySelector('.hero-content'); }
    private get _wrapContent() { return this.element.querySelector('.hero-section__wrap'); }
    private get _title() { return this.element.querySelector('.hero-section__title'); }
    private get _subTitle() { return this.element.querySelector('.hero-section__subtitle'); }
    private get _buttons() { return this.element.querySelectorAll('.hero-section__link-item'); }
    private get _imgWrap() { return this.element.querySelector('.hero-section__img'); }

    async setupSection() {
        this.setupAnimationItems();

        gsap.set(this._imgWrap, { opacity: 0 });
    }

    async start() {
        gsap.set(this._imgWrap, { opacity: 1 });

        await this.startInViewAnimation();
        this.setupScrollTriggers();

        await timeoutPromise(
            HeroThreeAnimationBridge.SceneAnimated.promise,
            3000,
            DelayForSetupScrollTriggers,
        );

        this.enableScroll();
    }

    private setupAnimationItems = () => {
        this.splittedAllSplitText = new SplitText(this._allSplitText, defaultSplitTextConfig);
        gsap.set(this.splittedAllSplitText.lines, { autoAlpha: 0 });
        addHeaderMenuColor(HeaderColorClass.HeroBlack);

        gsap.set([this._subTitle, this._buttons], { autoAlpha: 0 });
    };

    private startInViewAnimation = async () => {
        // enabling 3D as early as possible for start animation to show up
        HeroThreeAnimationBridge.isExternalInitialized.setTrue();

        // previously was called on `onEnter` of 3D's ST but it makes sense to run only once when page starts
        this.setBgColor('default');
        HeroThreeAnimationBridge.isActive.setTrue();

        this.disableScroll();

        gsap.set(this._subTitle, { autoAlpha: 0.7 });

        await gsap.timeline()
            .to(this._wrapContent, {
                y: 0,
                opacity: 1,
                duration: inFrames(70),
                ease: Easings.customEaseInOut,
            })
            .to(this._title, {
                yPercent: 0,
                duration: inFrames(70),
                ease: Easings.customEaseInOut,
            }, '<')
            .fromTo(this.splittedAllSplitText.lines, { autoAlpha: 0, yPercent: 100 }, {
                autoAlpha: 1,
                yPercent: 0,
                stagger: inFrames(2),
                duration: inFrames(40),
                ease: Easings.customEase,
            }, `<+=${inFrames(30)}`)
            .fromTo(this._buttons, { autoAlpha: 0, yPercent: 100 }, {
                autoAlpha: 1,
                yPercent: 0,
                stagger: inFrames(2),
                duration: inFrames(32),
                ease: Easings.customEase,
            }, `<-${inFrames(16)}`)
        ;

    };

    private setupScrollTriggers = () => {

        const timelineScroll = gsap.timeline({ defaults: { ease: 'none' } })
            .fromTo(this._wrapContent, { yPercent: 0, scale: 1 }, {
                yPercent: 50,
                scale: 0,
                duration: 1,
            });

        createScrollTrigger({
            trigger: this._content,
            start: 'top top',
            endTrigger: this._content,
            end: 'bottom-=50% top',
            pin: true,
            pinSpacing: false,
            scrub: true,
            animation: timelineScroll,
        });

        createScrollTrigger({
            trigger: this._imgWrap,
            endTrigger: this.element,
            start: 'top top',
            end: 'bottom bottom',
            pin: true,
            pinType: 'fixed',
            pinReparent: true,
            pinSpacing: false,
            scrub: true,
            animation: createProgressTimeline(HeroThreeAnimationBridge.progress/* , { logger: console } */),
            onLeave: () => {
                this.setBgColor('next');
                HeroThreeAnimationBridge.isActive.setFalse();
            },
            onEnterBack: () => {
                this.setBgColor('next');
                HeroThreeAnimationBridge.isActive.setTrue();
            },
        });

        const _currentBreakpointType = Breakpoints.Current.breakpoint.name;

        const colorConfigBlack: ChangeColorHeaderConfig = {
            element: this.element,
            color: HeaderColorClass.HeroBlack,
            triggerVars: {
                start: 'top-=10% top',
                end: headerColorChangeEnd[_currentBreakpointType],
            },
        };

        setupTriggerChangeColorHeader(colorConfigBlack);

        // NOTE: here's the best place to notify bridge that setup is completed since all ScrollTriggers are created and refreshed,
        // so linked progress will not jump to the end

        // HeroThreeAnimationBridge.isExternalInitialized.setTrue();
    };

    private enableScroll = () => {
        document.body.classList.remove(PRELOADER_ACTIVE_CLASS);
        this._smoother?.normalizer?.enable();
    };

    private disableScroll = () => {
        document.body.classList.add(PRELOADER_ACTIVE_CLASS);
        this._smoother?.normalizer?.disable();

        scrollToTop();
    };

    private setBgColor = (mode: 'default' | 'next') => {
        let color: string | number;
        switch (mode) {
            case 'next': {
                color = HomeHero.Colors.BackfaceExternal;
                break;
            }

            default: {
                color = HomeHero.Colors.Background.violet;
                break;
            }
        }
        gsap.set(this.element, { background: color });
    };
}

setupSection(HeroSection, { id: HomePageSections.Hero, start: setupSection.StartType.PageReady });
