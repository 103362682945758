import Section from 'scripts/core/section';
import gsap from 'gsap';
import { SplitText } from 'scripts/lib/gsap/splitText';
import { ScrollTrigger, createScrollTrigger } from 'scripts/lib/gsap/scrollTrigger';
import { HomePageSections } from 'common/views/sectionTypes';
import { HeaderColorClass, setupTriggerChangeColorHeader, ChangeColorHeaderConfig } from 'scripts/modules/changeColor';
import { inFrames } from 'scripts/utils/inFrames';
import { Easings } from 'scripts/utils/tweenHelpers';
import { setupSection } from 'scripts/utils/setupComponents';
import { Breakpoints } from 'common/views/appBreakpoints';

export default class FaqsSection extends Section {
    private isActivated: boolean;

    private triggerInView: ScrollTrigger;
    private triggerPinMain: ScrollTrigger;
    private splittedAllSplitText: SplitText;

    private get _allSplitText() { return this.element.querySelectorAll('.split'); }
    private get _wrap() { return this.element.querySelector('.faqs-wrap'); }
    private get _wrapMain() { return this.element.querySelector('.faqs-wrap__main'); }
    private get _title() { return this.element.querySelector('.faqs-title'); }
    private get _buttons() { return this.element.querySelectorAll('.faqs-btn'); }
    private get _accordions() { return this.element.querySelectorAll('.accordion .accordion-item'); }
    private get _accordionsContent() { return this.element.querySelectorAll('.accordion .inner'); }

    async setupScrollTriggers() {
        const timelineInView = gsap.timeline({
            defaults: {
                duration: inFrames(70),
                ease: Easings.customEaseInOut,
            },
        })
            .fromTo(this._title.querySelectorAll('.fade-overflow'), {
                y: 100,
            }, {
                y: 0,
                stagger: inFrames(5),
            }, 0)
            .fromTo(this._buttons, {
                y: 100,
            }, {
                y: 0,
                stagger: inFrames(2),
            }, `<+=${inFrames(4)}`)
            .fromTo(this._accordions, {
                y: 100,
            }, {
                y: 0,
                stagger: inFrames(2),
            }, `<+=${inFrames(1)}`)
            .fromTo(this._accordionsContent, {
                y: 30,
            }, {
                y: 0,
                stagger: inFrames(2),
            }, `<+=${inFrames(2)}`);

        this.triggerInView = createScrollTrigger({
            trigger: this._wrap,
            start: 'top bottom',
            end: 'top top',
            scrub: true,
            animation: timelineInView,
        });

        if (Breakpoints.isDesktop) {
            this.triggerPinMain = createScrollTrigger({
                trigger: this._wrap,
                start: 'top top',
                end: 'bottom bottom',
                pin: this._wrapMain,
                pinSpacing: false,
                scrub: true,
            });
        }
    }

    // The 'aria-expanded' attribute is set on an element to indicate if a control is expanded or collapsed,
    //  and whether or not the controlled elements are displayed or hidden.

    async setupSection() {

        const colorConfigBlack: ChangeColorHeaderConfig = {
            element: this.element,
            color: HeaderColorClass.Black,
            triggerVars: {
                start: 'top top',
                end: 'bottom top',
            },
        };

        setupTriggerChangeColorHeader(colorConfigBlack);

        this.splittedAllSplitText = new SplitText(this._allSplitText, { type: 'lines', linesClass: 'fade-overflow' });

        this.setupScrollTriggers();

    }

    protected _activate() {
        if (this.isActivated) {
            return;
        }
    }

    protected _deactivate() {
        /* TODO */
    }
}

setupSection(FaqsSection, { id: HomePageSections.FAQS });
