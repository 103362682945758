import Section from 'scripts/core/section';
import { inFrames } from 'scripts/utils/inFrames';
import { HomePageSections } from 'common/views/sectionTypes';
import gsap from 'gsap';
import { SplitText } from 'scripts/lib/gsap/splitText';
import { createScrollTrigger } from 'scripts/lib/gsap/scrollTrigger';
import { HeaderColorClass, setupTriggerChangeColorHeader, ChangeColorHeaderConfig } from 'scripts/modules/changeColor';
import { Breakpoints } from 'common/views/appBreakpoints';
import { ExperiencesThreeAnimationBridge, createProgressTimeline } from './animation/bridge';
import { setupSection } from 'scripts/utils/setupComponents';


const ANIMATION_DURATION = 100;
const PAUSE_DURATION = 5;

export default class ExperienceSection extends Section {
    private get _allSplitText() { return this.element.querySelectorAll('.split'); }

    private get _wrap() { return this.element.querySelector('.experience-wrap'); }
    private get _topContent() { return this.element.querySelector('.experience-top'); }
    private get _title() { return this.element.querySelector('.experience-title'); }
    private get _mainWrap() { return this.element.querySelector('.experience-main'); }
    private get _listWrap() { return this.element.querySelector('.experience-list-wrap'); }
    private get _pin() { return this.element.querySelector('.experience-pin'); }
    private get _listItems() { return this.element.querySelectorAll('.experience-item'); }

    async setupSection() {
        new SplitText(this._allSplitText, { type: 'lines', linesClass: 'fade-overflow' });
    }

    public start() {
        this.setupScrollTriggers();
    }

    private setupScrollTriggers() {
        this.setupHeaderColorChange();

        ExperiencesThreeAnimationBridge.isExternalInitialized.setTrue();

        if (Breakpoints.isDesktop) {
            this.setupTitleAnimation();
            this.setupDesktopTriggers();
        } else {
            this.setupTabletTriggers();
        }
    }

    private setupHeaderColorChange = () => {

        const colorConfig: ChangeColorHeaderConfig = {
            element: this.element,
            color: HeaderColorClass.Violet,
            triggerVars: {
                start: 'top bottom+=10%',
                end: 'bottom top',
            },
        };

        setupTriggerChangeColorHeader(colorConfig);
    };


    private setupTitleAnimation = () => {
        const timelineScroll = gsap.timeline()
            .to(this._topContent, { scale: 0.5, yPercent: -30 }, '<');

        // this.triggerScroll
        createScrollTrigger({
            trigger: this.element,
            start: 'top top',
            endTrigger: this._pin,
            end: 'center center',
            scrub: true,
            animation: timelineScroll,
            invalidateOnRefresh: false,
        });

    };


    private setupTabletTriggers = () => {
        const singleItemDuration = ANIMATION_DURATION / this._listItems.length - PAUSE_DURATION;
        const textItemDuration = singleItemDuration / 2 ;

        const mainScrollTl = gsap.timeline();
        const threeAnimationTl = createProgressTimeline(
            ExperiencesThreeAnimationBridge.progress,
            { referenceStTimeline: mainScrollTl, vars: { duration: ANIMATION_DURATION } },
        );


        const listTL = gsap.timeline({ defaults: { ease: 'none' } });

        this._listItems.forEach((item, i) => {
            const itemTl = gsap.timeline({ defaults: { ease: 'none' } });

            if (i === 0) {
                itemTl
                    .to({ wait: 0 }, { wait: 1, duration: PAUSE_DURATION })
                    .fromTo(item, { autoAlpha: 1 }, { autoAlpha: 0, duration: textItemDuration }, '>');
            } else if (i === this._listItems.length - 1) {
                itemTl
                    .fromTo(item, { autoAlpha: 0 }, { autoAlpha: 1, duration: textItemDuration })
                    .to({ wait: 0 }, { wait: 1, duration: PAUSE_DURATION }, '>');
            } else {
                itemTl
                    .fromTo(item, { autoAlpha: 0 }, { autoAlpha: 1, duration: textItemDuration })
                    .to({ wait: 0 }, { wait: 1, duration: PAUSE_DURATION })
                    .fromTo(item, { autoAlpha: 1 }, { autoAlpha: 0, duration: textItemDuration }, '>');
            }

            listTL.add(itemTl, '>');
        });

        listTL.duration(ANIMATION_DURATION - 20);
        threeAnimationTl.duration(ANIMATION_DURATION - 20);

        mainScrollTl
            .add(threeAnimationTl, 0)
            .add(listTL, 0)
            .to({ wait: 0 }, { wait: 1, duration: 10 }, ANIMATION_DURATION - 20)
            .to(this._listWrap, { y: 140, duration: 10 }, ANIMATION_DURATION - 10);

        // this.triggerPinMobile
        createScrollTrigger({
            trigger: this._mainWrap,
            start: 'top top',
            end: 'bottom+=10% bottom',
            pin: true,
            pinSpacing: false,
            scrub: true,
            animation: mainScrollTl,
        });
    };


    private setupDesktopTriggers = () => {

        this.setupTitleAnimation();

        const timelineInit = gsap.timeline()
            .fromTo(this._title, { yPercent: 10 }, { yPercent: 0 })
            .fromTo(this._title.querySelectorAll('.fade-overflow'), { yPercent: 50 }, { yPercent: 0, stagger: inFrames(5) }, `<+=${inFrames(5)}`);

        // this.triggerInit
        createScrollTrigger({
            trigger: this._wrap,
            start: 'top bottom',
            endTrigger: this.element,
            end: 'top top',
            scrub: true,
            animation: timelineInit,
        });

        const singleItemDuration = ANIMATION_DURATION / this._listItems.length - PAUSE_DURATION;
        const textItemDuration = singleItemDuration / 2 ;

        const mainScrollTl = gsap.timeline();
        const threeAnimationTL = createProgressTimeline(
            ExperiencesThreeAnimationBridge.progress,
            { referenceStTimeline: mainScrollTl, vars: { duration: ANIMATION_DURATION } },
        );

        const listTL = gsap.timeline();

        this._listItems.forEach((item, i) => {
            const itemTl = gsap.timeline({ defaults: { ease: 'none' } });

            if (i === 0) {
                itemTl
                    .to({ wait: 0 }, { wait: 1, duration: PAUSE_DURATION })
                    .fromTo(item, { autoAlpha: 1 }, { autoAlpha: 0.2, duration: textItemDuration }, '>');
            } else if (i === this._listItems.length - 1) {
                itemTl
                    .fromTo(item, { autoAlpha: 0.2 }, { autoAlpha: 1, duration: textItemDuration })
                    .to({ wait: 0 }, { wait: 1, duration: PAUSE_DURATION }, '>');
            } else {
                itemTl
                    .fromTo(item, { autoAlpha: 0.2 }, { autoAlpha: 1, duration: textItemDuration })
                    .to({ wait: 0 }, { wait: 1, duration: PAUSE_DURATION })
                    .fromTo(item, { autoAlpha: 1 }, { autoAlpha: 0.2, duration: textItemDuration }, '>');
            }

            listTL.add(itemTl, '>');
        });

        listTL.duration(100);

        mainScrollTl
            .add(threeAnimationTL)
            .add(listTL, '<');

        // this.triggerPin
        createScrollTrigger({
            trigger: this._pin,
            start: 'top top',
            endTrigger: this._listWrap,
            end: 'bottom bottom',
            pin: true,
            pinSpacing: false,
            scrub: true,
            animation: mainScrollTl,
        });
    };

    protected _activate() {
        ExperiencesThreeAnimationBridge.isActive.setTrue();
    }

    protected _deactivate() {
        ExperiencesThreeAnimationBridge.isActive.setFalse();
    }
}

setupSection(ExperienceSection, { id: HomePageSections.Experience, start: setupSection.StartType.PageReady });
