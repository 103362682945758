import Section from 'scripts/core/section';
import { HomePageSections } from 'common/views/sectionTypes';
import gsap from 'gsap';
import { SplitText } from 'scripts/lib/gsap/splitText';
import { ScrollTrigger, createScrollTrigger } from 'scripts/lib/gsap/scrollTrigger';
import { HeaderColorClass, setupTriggerChangeColorHeader, ChangeColorHeaderConfig } from 'scripts/modules/changeColor';
import { inFrames } from 'scripts/utils/inFrames';
import { Easings } from 'scripts/utils/tweenHelpers';
import LottieComponent from 'scripts/components/common/lottie';
import { BreakpointType, Breakpoints } from 'common/views/appBreakpoints';
import { setupSection } from 'scripts/utils/setupComponents';


const animOffsetY: Record<BreakpointType, number> = {
    [BreakpointType.Desktop]: 160,
    [BreakpointType.Tablet]: 100,
    [BreakpointType.Mobile]: 100,
};


export default class UnlockSection extends Section {
    private _lottieComponents: LottieComponent[] = [];

    private triggerInView: ScrollTrigger;
    private triggerScaleTitle: ScrollTrigger;
    private triggerExit: ScrollTrigger;

    private splittedAllSplitText: SplitText;

    private get _wrap() { return this.element.querySelector('.unlock-wrap'); }
    private get _subtitle() { return this.element.querySelector('.unlock-subtitle'); }
    private get _title() { return this.element.querySelector('.unlock-title'); }
    private get _list() { return this.element.querySelector('.unlock-list'); }
    private get _listItems() { return this.element.querySelectorAll('.unlock-item'); }
    private get _allSplitText() { return this.element.querySelectorAll('.split'); }

    private async _setupLotties() {
        const containers = this.element.querySelectorAll<HTMLImageElement>('.unlock-item__lottie');

        containers.forEach(async (container) => {
            const lottie = await new LottieComponent({
                el: container,
                autoplay: false,
                loop: false,
                priority: 12,
            }).setup();

            lottie.activate();

            container.addEventListener('mouseenter', () => {
                if (lottie.animation?.isPaused) {
                    lottie.animation?.setDirection(1);
                    lottie.animation?.goToAndPlay(0, true);
                }
            });

            this._lottieComponents.push(lottie);
        });
    }

    async setupScrollTriggersHeader() {

        const colorConfigWhite: ChangeColorHeaderConfig = {
            element: this.element,
            color: HeaderColorClass.White,
            triggerVars: {
                start: 'top top',
                end: 'bottom center',
            },
        };
        setupTriggerChangeColorHeader(colorConfigWhite);
    }

    async setupScrollTriggers() {
        const _currentBreakpointType = Breakpoints.Current.breakpoint.name;
        const _currentOffsetAnimY = animOffsetY[_currentBreakpointType];
        const timelineInView = gsap.timeline()
            .fromTo(this._subtitle, {
                y: _currentOffsetAnimY,
            }, {
                y: 0,
                duration: inFrames(80),
                ease: Easings.customEaseInOut,
            })
            .fromTo(this._title.querySelectorAll('.fade-overflow'), {
                y: _currentOffsetAnimY,
            }, {
                y: 0,
                duration: inFrames(80),
                ease: Easings.customEaseInOut,
                stagger: inFrames(2),
            }, inFrames(5))
            .fromTo(this._listItems, {
                y: _currentOffsetAnimY,
            }, {
                y: 0,
                duration: inFrames(80),
                ease: Easings.customEaseInOut,
                stagger: inFrames(2),
            }, `<${inFrames(2)}`);

        this.triggerInView = createScrollTrigger({
            trigger: this._wrap,
            start: 'top bottom',
            end: 'top top',
            scrub: 1,
            animation: timelineInView,
            invalidateOnRefresh: false,
        });

        if (Breakpoints.isDesktop) {
            const timelineScaleTitle = gsap.timeline()
                .to(this._title, { scale: 0.55, duration: inFrames(80), ease: Easings.customEaseInOut, transformOrigin: 'center left' });

            this.triggerScaleTitle = createScrollTrigger({
                trigger: this._wrap,
                start: 'top top',
                endTrigger: this._list,
                end: 'top top',
                scrub: 1,
                animation: timelineScaleTitle,
            });
        }


        this.triggerExit = createScrollTrigger({
            trigger: this._list,
            start: 'bottom bottom',
            end: 'bottom top',
            scrub: 1,
            pin: true,
            pinSpacing: false,
            animation: gsap.timeline()
                .fromTo(this._list, { x: 0 }, { x: 140, duration: inFrames(60), ease: Easings.customEaseInOut }),
        });
    }

    async hoverItem(index) {
        this._listItems.forEach((item, i) => {
            if (i !== index) {
                gsap.to(item, { opacity: 0.4, duration: inFrames(40), ease: Easings.customEaseInOut });
            }
        });
    }

    async unHoverItem(index) {
        this._listItems.forEach((item, i) => {
            if (i !== index) {
                gsap.to(item, { opacity: 1, duration: inFrames(40), ease: Easings.customEaseInOut });
            }
        });
    }

    async setupSection() {
        this.setupScrollTriggersHeader();

        this.splittedAllSplitText = new SplitText(this._allSplitText, { type: 'lines', linesClass: 'fade-overflow' });

        this._setupLotties();

        this.setupScrollTriggers();

        this._listItems.forEach((item, index) => {
            item.addEventListener('mouseenter', () => {
                this._lottieComponents[index].animation?.setDirection(1);
                this._lottieComponents[index].animation?.play();
                this.hoverItem(index);
            });

            item.addEventListener('mouseleave', () => {
                this._lottieComponents[index].animation?.setDirection(-1);
                this._lottieComponents[index].animation?.play();
                this.unHoverItem(index);
            });
        });
    }
}

setupSection(UnlockSection, { id: HomePageSections.Unlock });
