import { Breakpoints } from 'common/views/appBreakpoints';
import { HomePageSections } from 'common/views/sectionTypes';
import { CreateExitAnimation } from './PageHelpers';
import 'scripts/utils/detectBrowser';

// Create animations between sections
if (Breakpoints.isDesktop) {
    // Get sections
    const Experience = document.getElementById(HomePageSections.Experience);
    const Unlock = document.getElementById(HomePageSections.Unlock);
    const FiftyPlus = document.getElementById(HomePageSections.FiftyPlus);
    const Testimonials = document.getElementById(HomePageSections.Testimonials);
    const Campaigns = document.getElementById(HomePageSections.Campaigns);
    const JoinSection = document.getElementById(HomePageSections.Join);
    const FAQSection = document.getElementById(HomePageSections.FAQS);

    CreateExitAnimation(Experience, Unlock);
    CreateExitAnimation(FiftyPlus, Testimonials);
    CreateExitAnimation(Testimonials, Campaigns);
    CreateExitAnimation(FAQSection, JoinSection);
}
