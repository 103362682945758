
import Section from 'scripts/core/section';
import { HomePageSections } from 'common/views/sectionTypes';
import gsap from 'gsap';
import { createScrollTrigger } from 'scripts/lib/gsap/scrollTrigger';
import { inFrames } from 'scripts/utils/inFrames';
import { SplitText } from 'scripts/lib/gsap/splitText';
import { HeaderColorClass, setupTriggerChangeColorHeader, ChangeColorHeaderConfig } from 'scripts/modules/changeColor';
import { setupSection } from 'scripts/utils/setupComponents';
import { Breakpoints } from 'common/views/appBreakpoints';

enum BG_COLOR {
    RED = '#E1435F',
    BLUE = '#5DB1FE',
}

const MARKERS_ON = false;

export default class FiftyPlusSection extends Section {
    splittedFirstDesc: SplitText;
    splittedSecondDesc: SplitText;
    splittedThirdDesc: SplitText;

    private get _itemViolet() { return this.element.querySelector('.fifty-section__violet'); }
    private get _itemRed() { return this.element.querySelector('.fifty-section__red'); }
    private get _itemBlue() { return this.element.querySelector('.fifty-section__blue'); }

    private get _wrap() { return this.element.querySelector('.fifty-section__wrap'); }

    private get _firstTitle() { return this.element.querySelector('.fifty-section__item:nth-child(1) .anim-title'); }
    private get _secondTitle() { return this.element.querySelector('.fifty-section__item:nth-child(2) .anim-title'); }
    private get _thirdTitle() { return this.element.querySelector('.fifty-section__item:nth-child(3) .anim-title'); }

    private get _firstDesc() { return this.element.querySelectorAll('.fifty-section__item:nth-child(1) .anim-desc'); }
    private get _secondDesc() { return this.element.querySelectorAll('.fifty-section__item:nth-child(2) .anim-desc'); }
    private get _thirdDesc() { return this.element.querySelectorAll('.fifty-section__item:nth-child(3) .anim-desc'); }

    private get _items() { return this.element.querySelectorAll('.fifty-section__item'); }

    async setupInViewAnimation() {
        const inViewAnimMain = gsap.timeline({ defaults: { ease: 'none' } })
            .fromTo(this._itemViolet, { opacity: 0 }, { opacity: 1, duration: inFrames(40) }, 0)
            .fromTo(this._firstTitle, { scale: 20 }, { scale: 1, duration: inFrames(70) }, inFrames(40))
            .fromTo(this._firstTitle, { opacity: 0 }, { opacity: 1, duration: inFrames(40) }, inFrames(40))
            .fromTo(this.splittedFirstDesc.lines, { autoAlpha: 0 }, { autoAlpha: 1, stagger: inFrames(1.5), duration: inFrames(5) }, inFrames(95))
            .fromTo(this.splittedFirstDesc.lines, { y: 500 }, { y: 0, stagger: inFrames(0.5), duration: inFrames(20) }, inFrames(95));

        const inViewAnimRoot = gsap.timeline({ defaults: { ease: 'none' } })
            .add(inViewAnimMain, 0)
            .fromTo(
                this._itemViolet,
                {
                    yPercent: -100,
                },
                {
                    yPercent: 0,
                    duration: inViewAnimMain.duration(),
                },
                0,
            )
        ;

        createScrollTrigger({
            trigger: this.element,
            start: 'top bottom',
            end: 'top top',
            scrub: true,
            markers: MARKERS_ON,
            animation: inViewAnimRoot,
        });
    }

    async setupAnimationScroll() {

        createScrollTrigger({
            trigger: this.element,
            start: 'top top-=50%',
            end: 'bottom bottom+=20%',
            scrub: true,
            markers: MARKERS_ON,
            animation: gsap.timeline({ defaults: { ease: 'none' } })
                .fromTo(this._secondTitle, { autoAlpha: 0.2 }, { autoAlpha: 1, duration: inFrames(40) }, 0)
                .to(this._items, { background: BG_COLOR.RED, duration: inFrames(80) }, '<')
                .fromTo(this.splittedSecondDesc.lines, { autoAlpha: 0.2, y: 100 }, { autoAlpha: 1, y: 0, stagger: inFrames(2), duration: inFrames(60) }, `<+=${inFrames(1)}`)
                .addPause(inFrames(120))
                .fromTo(this._thirdTitle, { autoAlpha: 0.2 }, { autoAlpha: 1, duration: inFrames(70) })
                .to(this._items, { background: BG_COLOR.BLUE, duration: inFrames(80) }, '<')
                .fromTo(this.splittedThirdDesc.lines, { autoAlpha: 0.2, y: 100 }, { autoAlpha: 1, y: 0, stagger: inFrames(2), duration: inFrames(60) }, `<+=${inFrames(1)}`)
            ,
        });
    }

    async changeColorHeader() {

        const colorConfigViolet: ChangeColorHeaderConfig = {
            element: this._itemViolet as HTMLElement,
            color: HeaderColorClass.Violet,
            triggerVars: {
                start: 'top top',
                end: 'bottom+=150% bottom',
            },
        };

        const colorConfigRed: ChangeColorHeaderConfig = {
            element: this._itemRed as HTMLElement,
            color: HeaderColorClass.Red,
            triggerVars: {
                start: 'top center',
                end: 'bottom center',
            },
        };

        const colorConfigBlue: ChangeColorHeaderConfig = {
            element: this._itemBlue as HTMLElement,
            color: HeaderColorClass.Blue,
            triggerVars: {
                start: 'top center',
                end: 'bottom top',
            },
        };

        setupTriggerChangeColorHeader(colorConfigBlue);
        setupTriggerChangeColorHeader(colorConfigRed);
        setupTriggerChangeColorHeader(colorConfigViolet);
    }

    // async setupExitAnimation() {
    //     this.triggerExit = createScrollTrigger({
    //         trigger: this._wrap,
    //         start: 'bottom bottom',
    //         end: 'bottom top',
    //         scrub: true,
    //         pin: true,
    //         pinSpacing: false,
    //         markers: MARKERS_ON,
    //         animation: gsap.timeline().to(this._wrap, { yPercent: 3.5, duration: inFrames(80) }),
    //     });
    // }

    async setupSection() {
        this.splittedFirstDesc = new SplitText(this._firstDesc, { type: 'lines' });
        this.splittedSecondDesc = new SplitText(this._secondDesc, { type: 'lines' });
        this.splittedThirdDesc = new SplitText(this._thirdDesc, { type: 'lines' });
        if (Breakpoints.isDesktop) {
            this.setupInViewAnimation();
        }

        this.setupAnimationScroll();

        // this.setupExitAnimation();

        this.changeColorHeader();
    }

}

setupSection(FiftyPlusSection, { id: HomePageSections.FiftyPlus });
